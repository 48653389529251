import { Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import FollowerIcon from 'assets/discovery/followers-icon.png';
import EngIcon from 'assets/discovery/Eng-Rate-icon.png';
import AvgViewIcon from 'assets/discovery/Avg-View-icon.png';
import AvgEngIcon from 'assets/discovery/Avg-Eng-Icon.png';
import PPPIcon from 'assets/discovery/PPP-icon.png';

import StatsCard from './components/StatsCard';

function BasicStats({
  followers,
  engagement_rate,
  avg_views,
  paid_post_performance,
  engagements,
  audience_followers_credibility,
  filteredData,
}) {
  const classes = useStyles();
  function credibility(value) {
    if (value) {
      return parseFloat(value * 100).toFixed(2) + '%';
    } else {
      return '';
    }
  }
  function floatval(value){
    if(value){
     return value.toFixed(3)
    }
  }
  return (
    <Grid container style={{ height: 80 }} justifyContent="center" alignItems="center">
      <Grid item md={2}>
        <StatsCard value={followers} label={'Followers'} iconSrc={FollowerIcon} />
      </Grid>
      <Grid item md={2}>
        <StatsCard
          value={
            filteredData.organic_posts_engagement_rate
              ? filteredData.organic_posts_engagement_rate
              : filteredData.paid_posts_engagement_rate
          }
          label={'Engagement Rate'}
          iconSrc={EngIcon}
        />
      </Grid>
      <Grid item md={2}>
        <StatsCard
          value={
            filteredData.organic_posts_avg_views
              ? filteredData.organic_posts_avg_views
              : filteredData.paid_posts_avg_views
              ? filteredData.paid_posts_avg_views
              : 0
          }
          label={'Average Views'}
          iconSrc={AvgViewIcon}
        />
      </Grid>
      <Grid item md={2}>
        <StatsCard
          value={
            filteredData.organic_posts_avg_engagement
              ? filteredData.organic_posts_avg_engagement
              : filteredData.paid_posts_avg_engagement
          }
          label={'Average Engagement'}
          iconSrc={AvgEngIcon}
        />
      </Grid>
      <Grid item md={2}>
        <StatsCard
          value={paid_post_performance}
          label={'Paid Post Performance'}
          iconSrc={PPPIcon}
        />
      </Grid>
      <Grid item md={2}>
        <StatsCard
          value={credibility(audience_followers_credibility)}
          label={'Audience Credibility'}
          iconSrc={FollowerIcon}
        />
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles(() => ({
  root: {
    // paddingTop: '3px !important',
    // paddingBottom: '3px !important',
    cursor: 'pointer',
  },
}));

export default BasicStats;

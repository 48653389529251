import axios from 'axios';
import { API_HOST } from '../config';
import store from '../store';
import history from '../history';
import moment from 'moment';
let download = require('downloadjs');

const getHeaders = () => {
  return new Headers({
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('access')}`,
  });
};

const formatResponse = (response) => {
  if ([200, 201, 400].includes(response.status)) return response.json();
};

export function getListCompetitorBrands(brand) {
  let baseURL = `${API_HOST}/api/v1/competitor-analysis/fetched-competitors?brand=${brand}`;

  return fetch(baseURL, {
    method: 'GET',
    headers: getHeaders(),
  }).then(formatResponse);
}

export function saveBrandRequestToDB(payload) {
  console.log(' == =  saveBrandRequestToDB', saveBrandRequestToDB);
  let baseURL = `${API_HOST}/api/v1/competitor-analysis/fetched-competitors`;

  return fetch(baseURL, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: getHeaders(),
  }).then(formatResponse);
}

export function topCreatorFetch(brandName, startDate, endDate, searchKeyword, paidPost) {
  const state = store.getState();
  let baseURL = `${API_HOST}/api/v1/competitor-analysis/${brandName}/top-tagged-posts?keyword=${searchKeyword}&paid_post=${paidPost}`;
  if (startDate && endDate) {
    baseURL += `&start_date=${moment(startDate).format('YYYY-MM-DD')}&end_date=${moment(
      endDate,
    ).format('YYYY-MM-DD')}`;
  }
  return fetch(baseURL, {
    method: 'GET',
    headers: getHeaders(),
  }).then(formatResponse);
}

export function campaignTypesFetch(brandName, startDate, endDate, searchKeyword, paidPost) {
  const state = store.getState();
  let baseURL = `${API_HOST}/api/v1/competitor-analysis/${brandName}/aggregated-by-type?keyword=${searchKeyword}&paid_post=${paidPost}`;
  if (startDate && endDate) {
    baseURL += `&start_date=${moment(startDate).format('YYYY-MM-DD')}&end_date=${moment(
      endDate,
    ).format('YYYY-MM-DD')}`;
  }
  return fetch(baseURL, {
    method: 'GET',
    headers: getHeaders(),
  }).then(formatResponse);
}

export function creatorTypesFetch(brandName, startDate, endDate, searchKeyword, paidPost) {
  const state = store.getState();
  let baseURL = `${API_HOST}/api/v1/competitor-analysis/${brandName}/aggregated-by-creator-type?keyword=${searchKeyword}&paid_post=${paidPost}`;
  if (startDate && endDate) {
    baseURL += `&start_date=${moment(startDate).format('YYYY-MM-DD')}&end_date=${moment(
      endDate,
    ).format('YYYY-MM-DD')}`;
  }
  return fetch(baseURL, {
    method: 'GET',
    headers: getHeaders(),
  }).then(formatResponse);
}

export function ytTopCreatorFetch(brandName, startDate, endDate, searchKeyword, paidPost) {
  const state = store.getState();
  let baseURL = `${API_HOST}/api/v1/competitor-analysis/youtube/${brandName}/top-tagged-posts?keyword=${searchKeyword}&paid_post=${paidPost}`;
  if (startDate && endDate) {
    baseURL += `&start_date=${moment(startDate).format('YYYY-MM-DD')}&end_date=${moment(
      endDate,
    ).format('YYYY-MM-DD')}`;
  }
  return fetch(baseURL, {
    method: 'GET',
    headers: getHeaders(),
  }).then(formatResponse);
}

export function ytCampaignTypesFetch(brandName, startDate, endDate, searchKeyword, paidPost) {
  const state = store.getState();
  let baseURL = `${API_HOST}/api/v1/competitor-analysis/youtube/${brandName}/aggregated-by-type?keyword=${searchKeyword}&paid_post=${paidPost}`;
  if (startDate && endDate) {
    baseURL += `&start_date=${moment(startDate).format('YYYY-MM-DD')}&end_date=${moment(
      endDate,
    ).format('YYYY-MM-DD')}`;
  }
  return fetch(baseURL, {
    method: 'GET',
    headers: getHeaders(),
  }).then(formatResponse);
}

export function campaignTypesYoutubeFetch(brandName, startDate, endDate, searchKeyword, paidPost) {
  const state = store.getState();
  let baseURL = `${API_HOST}/api/v1/competitor-analysis/youtube/${brandName}/aggregated-by-type?keyword=${searchKeyword}&paid_post=${paidPost}`;
  if (startDate && endDate) {
    baseURL += `&start_date=${moment(startDate).format('YYYY-MM-DD')}&end_date=${moment(
      endDate,
    ).format('YYYY-MM-DD')}`;
  }
  return fetch(baseURL, {
    method: 'GET',
    headers: getHeaders(),
  }).then(formatResponse);
}

export function creatorTypesYoutubeFetch(brandName, startDate, endDate, searchKeyword, paidPost) {
  const state = store.getState();
  let baseURL = `${API_HOST}/api/v1/competitor-analysis/youtube/${brandName}/aggregated-by-creator-type?keyword=${searchKeyword}&paid_post=${paidPost}`;
  if (startDate && endDate) {
    baseURL += `&start_date=${moment(startDate).format('YYYY-MM-DD')}&end_date=${moment(
      endDate,
    ).format('YYYY-MM-DD')}`;
  }
  return fetch(baseURL, {
    method: 'GET',
    headers: getHeaders(),
  }).then(formatResponse);
}

export function getHandlerData(keyword, creatorType, pageNum, searchKeyword, paidPost, sort_by_views) {
  const apiPath = `${API_HOST}/api/v1/competitor-analysis/${keyword}/tagged-posts`;
  const params = new URLSearchParams({
    ...(creatorType !== null && { creator_type: creatorType }),
    page: pageNum,
    keyword: searchKeyword,
    paid_post: paidPost,
    sort_by_views: sort_by_views
  });
  return fetch(`${apiPath}?${params.toString()}`, {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('access')}`,
    }),
  }).then((response) => {
    if ([200, 201, 400].includes(response.status)) return response.json();
  });
}

export function getYtHandlerData(keyword, creatorType, pageNum, startDate, endDate, searchKeyword, paidPost, sort_by_views) {
  const apiPath = `${API_HOST}/api/v1/competitor-analysis/youtube/${keyword}/tagged-posts`;
  const params = new URLSearchParams({
    ...(creatorType !== null && { creator_type: creatorType }),
    ...(startDate !== null && { start_date: startDate }),
    ...(endDate !== null && { end_date: endDate }),
    page: pageNum,
    keyword: searchKeyword ,
    paid_post: paidPost,
    sort_by_views: sort_by_views
  });
  return fetch(`${apiPath}?${params.toString()}`, {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('access')}`,
    }),
  }).then((response) => {
    if ([200, 201, 400].includes(response.status)) return response.json();
  });
}
export function downloadInstagramData(brandName, startDate, endDate, searchKeyword, paidPost) {
  let apiPath = `${API_HOST}/api/v1/competitor-analysis/${brandName}/download?start_date=${moment(
    startDate,
  ).format('YYYY-MM-DD')}&end_date=${moment(endDate).format('YYYY-MM-DD')}&keyword=${searchKeyword}&paid_post=${paidPost}`;

  return fetch(apiPath, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('access')}`,
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      return response.blob();
    })
    .then(function (blob) {
      download(blob, 'download.csv', 'application/csv');
    });
}

export function downloadYtData(brandName, startDate, endDate, searchKeyword, paidPost) {
  let apiPath = `${API_HOST}/api/v1/competitor-analysis/youtube/${brandName}/download?start_date=${moment(
    startDate,
  ).format('YYYY-MM-DD')}&end_date=${moment(endDate).format('YYYY-MM-DD')}&keyword=${searchKeyword}&paid_post=${paidPost}`;
  return fetch(apiPath, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('access')}`,
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {

      return response.blob();
    })
    .then(function (blob) {
      download(blob, 'download.csv', 'application/csv');
    });
}

export function fetchCreatorTypeCategory(brandName, startDate, endDate, metric, searchKeyword, paidPost){
  const state = store.getState();
  let baseURL = `${API_HOST}/api/v1/competitor-analysis/${brandName}/category-by-type?sort_by=${metric}&keyword=${searchKeyword}&paid_post=${paidPost}`;
  if (startDate && endDate) {
    baseURL += `&start_date=${moment(startDate).format('YYYY-MM-DD')}&end_date=${moment(
      endDate,
    ).format('YYYY-MM-DD')}`;
  }
  return fetch(baseURL, {
    method: 'GET',
    headers: getHeaders(),
  }).then(formatResponse);
}

export function fetchYtCreatorTypeCategory(brandName, startDate, endDate, metric, searchKeyword, paidPost){
  const state = store.getState();
  let baseURL = `${API_HOST}/api/v1/competitor-analysis/youtube/${brandName}/category-by-type?sort_by=${metric}&keyword=${searchKeyword}&paid_post=${paidPost}`;
  if (startDate && endDate) {
    baseURL += `&start_date=${moment(startDate).format('YYYY-MM-DD')}&end_date=${moment(
      endDate,
    ).format('YYYY-MM-DD')}`;
  }
  return fetch(baseURL, {
    method: 'GET',
    headers: getHeaders(),
  }).then(formatResponse);
}


export function fetchYTPostsOverTime(brandName, startDate, endDate, searchKeyword, paidPost) {
  const state = store.getState();
  let baseURL = `${API_HOST}/api/v1/competitor-analysis/youtube/${brandName}/chart-post-count?keyword=${searchKeyword}&paid_post=${paidPost}`;
  if (startDate && endDate) {
    baseURL += `&start_date=${moment(startDate).format('YYYY-MM-DD')}&end_date=${moment(
      endDate,
    ).format('YYYY-MM-DD')}`;
  }
  return fetch(baseURL, {
    method: 'GET',
    headers: getHeaders(),
  }).then(formatResponse);
}


export function fetchInstaPostsOverTime(brandName, startDate, endDate, searchKeyword, paidPost) {
  const state = store.getState();
  let baseURL = `${API_HOST}/api/v1/competitor-analysis/${brandName}/chart-post-count?keyword=${searchKeyword}&paid_post=${paidPost}`;
  if (startDate && endDate) {
    baseURL += `&start_date=${moment(startDate).format('YYYY-MM-DD')}&end_date=${moment(
      endDate,
    ).format('YYYY-MM-DD')}`;
  }
  return fetch(baseURL, {
    method: 'GET',
    headers: getHeaders(),
  }).then(formatResponse);
}

export function creatorsFetch(brandName, startDate, endDate, searchKeyword, paidPost, comp_handle, excluded_handles) {
  const state = store.getState();
  let baseURL = `${API_HOST}/api/v1/competitor-analysis/${brandName}/creators?keyword=${searchKeyword}&paid_post=${paidPost}`;
  if (startDate && endDate) {
    baseURL += `&start_date=${moment(startDate).format('YYYY-MM-DD')}&end_date=${moment(
      endDate,
    ).format('YYYY-MM-DD')}`;
  }
  if (comp_handle) {
    baseURL += `&brand_handle_comp=${comp_handle}`
  }
  if (excluded_handles) {
    baseURL += `&excluded_handles=${excluded_handles}`
  }
  return fetch(baseURL, {
    method: 'GET',
    headers: getHeaders(),
  }).then(formatResponse);
}

export function ytCreatorsFetch(brandName, startDate, endDate, searchKeyword, paidPost, comp_handle, excluded_channel_ids) {
  const state = store.getState();
  let baseURL = `${API_HOST}/api/v1/competitor-analysis/youtube/${brandName}/creators?keyword=${searchKeyword}&paid_post=${paidPost}`;
  if (startDate && endDate) {
    baseURL += `&start_date=${moment(startDate).format('YYYY-MM-DD')}&end_date=${moment(
      endDate,
    ).format('YYYY-MM-DD')}`;
  }
  if (comp_handle) {
    baseURL += `&brand_handle_comp=${comp_handle}`;
  }
  if (excluded_channel_ids) {
    baseURL += `&excluded_channel_ids=${excluded_channel_ids}`
  }
  return fetch(baseURL, {
    method: 'GET',
    headers: getHeaders(),
  }).then(formatResponse);
}

export function getBase64HandlerData(keyword, creatorType, pageNum, searchKeyword, paidPost, sort_by_views, startDate, endDate, excluded_handles, included_insta_shortcodes) {
  const apiPath = `${API_HOST}/api/v1/competitor-analysis/${keyword}/tagged-posts-base64-post`;
  return fetch(`${apiPath}`, {
    method: 'POST',
    body: JSON.stringify({
      ...(creatorType !== null && { creator_type: creatorType }),
      ...(startDate !== null && { start_date: startDate }),
      ...(endDate !== null && { end_date: endDate }),
      page: pageNum,
      keyword: searchKeyword,
      paid_post: paidPost,
      sort_by_views: sort_by_views,
      excluded_handles: excluded_handles || "",
      included_insta_shortcodes: included_insta_shortcodes || ""
    }),
    headers: getHeaders(),
  }).then((response) => {
    if ([200, 201, 400].includes(response.status)) return response.json();
  });
}
export function getBase64YtHandlerData(keyword, creatorType, pageNum, startDate, endDate, searchKeyword, paidPost, sort_by_views, excluded_channel_ids) {
  const apiPath = `${API_HOST}/api/v1/competitor-analysis/youtube/${keyword}/tagged-posts-base64`;
  const params = new URLSearchParams({
    ...(creatorType !== null && { creator_type: creatorType }),
    ...(startDate !== null && { start_date: startDate }),
    ...(endDate !== null && { end_date: endDate }),
    page: pageNum,
    keyword: searchKeyword ,
    paid_post: paidPost,
    sort_by_views: sort_by_views,
    excluded_channel_ids: excluded_channel_ids || ""
  });
  return fetch(`${apiPath}?${params.toString()}`, {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('access')}`,
    }),
  }).then((response) => {
    if ([200, 201, 400].includes(response.status)) return response.json();
  });
}

export function getCombinedBrandListCompetitor(brand) {
  let baseURL = `${API_HOST}/api/v1/competitor-analysis/combined_brand_list?brand=${brand}`;

  return fetch(baseURL, {
    method: 'GET',
    headers: getHeaders(),
  }).then(formatResponse);
}

export function creatorTypesWithNormalTypeFetch(brandName, startDate, endDate, searchKeyword, paidPost, excluded_handles) {
  const state = store.getState();
  let baseURL = `${API_HOST}/api/v1/competitor-analysis/${brandName}/aggregated-by-creator-type-wth-normal-type?keyword=${searchKeyword}&paid_post=${paidPost}`;
  if (startDate && endDate) {
    baseURL += `&start_date=${moment(startDate).format('YYYY-MM-DD')}&end_date=${moment(
      endDate,
    ).format('YYYY-MM-DD')}`;
  }
  if (excluded_handles && excluded_handles.length > 0) {
    baseURL += `&excluded_handles=${excluded_handles}`
  }
  return fetch(baseURL, {
    method: 'GET',
    headers: getHeaders(),
  }).then(formatResponse);
}

export function creatorTypesYoutubeWithNormalTypeFetch(brandName, startDate, endDate, searchKeyword, paidPost, excluded_channel_ids) {
  const state = store.getState();
  let baseURL = `${API_HOST}/api/v1/competitor-analysis/youtube/${brandName}/aggregated-by-creator-type-wth-normal-type?keyword=${searchKeyword}&paid_post=${paidPost}`;
  if (startDate && endDate) {
    baseURL += `&start_date=${moment(startDate).format('YYYY-MM-DD')}&end_date=${moment(
      endDate,
    ).format('YYYY-MM-DD')}`;
  }
  if (excluded_channel_ids && excluded_channel_ids.length > 0) {
    baseURL += `&excluded_channel_ids=${excluded_channel_ids}`
  }
  return fetch(baseURL, {
    method: 'GET',
    headers: getHeaders(),
  }).then(formatResponse);
}

export function updatePostData(payload) {
  let baseURL = `${API_HOST}/api/v1/competitor-analysis/update_post_data`;

  return fetch(baseURL, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: getHeaders(),
  }).then(formatResponse);
}

export function updateProfileData(payload) {
  let baseURL = `${API_HOST}/api/v1/competitor-analysis/update_profile_data`;

  return fetch(baseURL, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: getHeaders(),
  }).then(formatResponse);
}

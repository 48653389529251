import PersonAddIcon from '@mui/icons-material/PersonAdd';
import AddLinkIcon from '@mui/icons-material/AddLink';
import ScreenSearchDesktopIcon from '@mui/icons-material/ScreenSearchDesktop';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import CloseIcon from '@mui/icons-material/Close';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { Box, Button, Grid, IconButton, Modal, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import mixpanel from 'mixpanel-browser';
import { useHistory } from 'react-router-dom';
import { uploadCsvFile, addCollabProfileLinks, colabImportStatus } from 'api_handlers/planner2';

function AddInfluencerModal({
  open,
  close,
  planPlatform,
  planUUID,
  bulkUploadingStatus,
  downloadReport,
  statusData,
}) {
  const [openUploadCsvModal, setOpenUploadCsvModal] = useState(false);
  const [openAddBulkModal, setOpenAddBulkModal] = useState(false);
  const [instaSelected, setInstaSelected] = useState(false);
  const [ytSelected, setYtSelected] = useState(false);
  const [text, setText] = useState('');
  const [instaLinks, setInstaLinks] = useState([]);
  const [ytLinks, setYtLinks] = useState([]);
  const [file, setFile] = useState(null);
  const [platform, setPlatform] = useState(
    planPlatform === 'instagram' ? 'instagram' : planPlatform === 'youtube' ? 'youtube' : '',
  );
  const history = useHistory();

  const [csvFile, setCsvFile] = useState(null);
  // const [statusData, setStatusData] = useState({
  //   total_count: 0,
  //   success_count: 0,
  //   failed_count: 0,
  // });

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
    }
  };

  const handleDiscoverClick = () => {
    if (process.env.NODE_ENV === 'production') {
      mixpanel.track('collab_list_add_influencer');
    }
    history.push('/planner/discover');
  };

  // api to upload csv
  const handleCsvUpload = async (csvFile) => {
    if (!csvFile) {
      alert('Please select a file to upload.');
      return;
    }
    try {
      // const response = await uploadCsvFile(planUUID, csvFile);

      // console.log('CSV upload response:', response);
      await uploadCSVFiles(csvFile);
      setOpenUploadCsvModal(false);
    } catch (error) {
      console.error('Error uploading CSV file:', error);
    }
  };

  const uploadCSVFiles = (csvFile) => {
    const response = uploadCsvFile(planUUID, csvFile);
    const interval = setInterval(() => {
      importStatus(interval);
    }, 2);
  };

  const collabBulkLink = () => {
    const response = addCollabProfileLinks(planUUID, instaLinks, ytLinks);
    // const response2 =  colabImportStatus(planUUID);
    const interval = setInterval(() => {
      importStatus(interval);
    }, 2);
  };

  // api to bulk upload link
  const handleLinkUpload = async () => {
    if (!instaLinks.length && !ytLinks.length) {
      alert('Please add links to upload.');
      return;
    }
    try {
      await collabBulkLink();
      // const response = await addCollabProfileLinks(planUUID, instaLinks, ytLinks); // Create and use uploadLinks API
      // const statusInterval = setInterval(async()=>{
      //  await importStatus()
      // },3000)
      // const timeOut = setTimeout=(async()=>{
      //   await clearInterval(statusInterval)
      // }, 3000)
      setOpenAddBulkModal(false);
    } catch (error) {
      console.error('Error uploading links:', error);
    }
  };

  // api to check the progress
  const importStatus = async (interval) => {
    try {
      const response = await colabImportStatus(planUUID);
      // console.log('importStatus', response);
      if (response.total_count === response.success_count + response.failed_count) {
        clearInterval(interval);
      }
      statusData({
        total_count: response.total_count || 0,
        success_count: response.success_count || 0,
        failed_count: response.failed_count || 0,
      });
      // setStatusData({
      //   total_count: response.total_count || 0,
      //   success_count: response.success_count || 0,
      //   failed_count: response.failed_count || 0,
      // });
    } catch (error) {
      console.error('Error fetching plan import status:', error);
    }
  };

  const handleOnChange = (event) => {
    if (platform === 'instagram') {
      const value = event.target.value;
      setText(value);
      const link = value.split(`\n`);
      setInstaLinks(link);
    } else if (platform === 'youtube') {
      const value = event.target.value;
      setText(value);
      const link = value.split(`\n`);
      setYtLinks(link);
    }
  };

  
  return (
    <Grid container>
      <Modal
        open={open}
        //   onClose={handleAddInfluencersClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 500,
            bgcolor: 'background.paper',
          }}
        >
          {!openUploadCsvModal && !openAddBulkModal ? (
            <Grid container width={'100%'}>
              <Grid item width={'100%'} sx={{ borderBottom: '1px solid #f0f0f0', p: '12px' }}>
                <Grid
                  container
                  // justifyContent={'center'}
                  alignItems={'center'}
                  spacing={1}
                >
                  <Grid item md={1} sx={{ ml: '8px' }}>
                    <PersonAddIcon></PersonAddIcon>
                  </Grid>
                  <Grid item md={9} sx={{ fontSize: 17, fontWeight: 700 }}>
                    Add Influencers
                  </Grid>
                  <Grid item md={1}>
                    <IconButton onClick={() => close(false)}>
                      <CloseIcon></CloseIcon>
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item width={'100%'} sx={{ p: '18px' }}>
                <Grid container justifyContent={'center'} alignItems={'center'}>
                  <Grid item sx={{ background: '#f9f9fa', width: '100%', p: '4px' }}>
                    <Grid
                      container
                      justifyContent={'center'}
                      alignItems={'center'}
                      // spacing={}
                    >
                      <Grid item md={1}>
                        <UploadFileIcon sx={{ mt: '4px' }}></UploadFileIcon>
                      </Grid>
                      <Grid item md={8}>
                        <Typography sx={{ fontSize: '12px', fontWeight: 600 }}>
                          Upload CSV,XLS,XLSX
                        </Typography>
                      </Grid>
                      <Grid item md={3}>
                        <Button
                          variant="contained"
                          sx={{ textTransform: 'none', height: '32px' }}
                          onClick={() => setOpenUploadCsvModal(true)}
                        >
                          Add Now
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item sx={{ background: '#f9f9fa', width: '100%', p: '4px', mt: '6px' }}>
                    <Grid
                      container
                      justifyContent={'center'}
                      alignItems={'center'}
                      // spacing={}
                    >
                      <Grid item md={1}>
                        <AddLinkIcon sx={{ mt: '4px' }}></AddLinkIcon>
                      </Grid>
                      <Grid item md={8}>
                        <Typography sx={{ fontSize: '12px', fontWeight: 600 }}>
                          Import Bulk Links
                        </Typography>
                      </Grid>
                      <Grid item md={3}>
                        <Button
                          variant="contained"
                          sx={{ textTransform: 'none', height: '32px' }}
                          onClick={() => setOpenAddBulkModal(true)}
                        >
                          Add Now
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item sx={{ background: '#f9f9fa', width: '100%', p: '4px', mt: '6px' }}>
                    <Grid container justifyContent={'center'} alignItems={'center'}>
                      <Grid item md={1}>
                        <ScreenSearchDesktopIcon sx={{ mt: '4px' }}></ScreenSearchDesktopIcon>
                      </Grid>
                      <Grid item md={8}>
                        <Typography sx={{ fontSize: '12px', fontWeight: 600 }}>
                          Add From Discover
                        </Typography>
                      </Grid>
                      <Grid item md={3}>
                        <Button
                          variant="contained"
                          sx={{ textTransform: 'none', height: '32px' }}
                          onClick={handleDiscoverClick}
                        >
                          Add Now
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : openUploadCsvModal ? (
            <Grid container width={'100%'}>
              <Grid item width={'100%'} sx={{ borderBottom: '1px solid #f0f0f0', p: '12px' }}>
                <Grid container alignItems={'center'}>
                  <Grid item md={11}>
                    <Typography sx={{ fontSize: '20px', fontWeight: 600 }}>
                      Upload Influencers
                    </Typography>
                  </Grid>
                  <Grid item md={1}>
                    <IconButton onClick={() => close(false)}>
                      <CloseIcon></CloseIcon>
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item width={'100%'} height={300} sx={{ p: '18px' }}>
                <Grid
                  container
                  height={200}
                  sx={{ border: '1px dashed #454545', borderRadius: 1 }}
                  direction={'column'}
                  //   justifyContent={'center'}
                  alignItems={'center'}
                >
                  <Grid item>
                    <CloudUploadIcon sx={{ height: 50 }}></CloudUploadIcon>
                  </Grid>
                  <Grid item>
                    <Typography sx={{ fontSize: 20 }}>Drop files here</Typography>
                  </Grid>
                  <Grid item>
                    <Typography sx={{ mt: 1, color: '#595b5f' }}>
                      Supported format: CSV, XLS, XLSX
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography sx={{ mt: 2, fontWeight: 600 }}>OR</Typography>
                  </Grid>

                  <Grid item>
                    <Typography
                      sx={{ mt: 2, color: '#febd1c', cursor: 'pointer' }}
                      onClick={() => {
                        document.getElementById('file-input').click();
                      }}
                    >
                      Browse files
                    </Typography>
                    <input
                      type="file"
                      id="file-input"
                      accept=".xls,.xlsx,.csv" // Only allow Excel files
                      style={{ display: 'none' }}
                      onChange={handleFileChange}
                    />
                  </Grid>
                  <Grid item>
                    <Typography>{file ? file.name : ''}</Typography>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container sx={{ mt: 1 }}>
                    <Grid item>
                      <Typography sx={{ fontSize: '11px', color: '#707070',width:'87%' }}>
                        Note - Please download the sample sheet and upload the same, you can add upto 
                        <span style={{fontWeight:700,color:'#333'}}> 30 influencers</span> at a time.
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="outlined"
                        endIcon={<SystemUpdateAltIcon></SystemUpdateAltIcon>}
                        sx={{
                          textTransform: 'none',
                          color: '#000',
                          borderColor: '#d8dbe0',
                          mt: 1,
                          height: 30,
                        }}
                        onClick={() => {
                          window.open(
                            'https://docs.google.com/spreadsheets/d/1FgpO-AdjnHm51z_z8AXXiXTalHa1paEwWatCeVqdgEw/edit?usp=sharing',
                            '_blank',
                          );
                        }}
                      >
                        Sample Sheet
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item width={'100%'} sx={{ p: '18px' }}>
                <Grid container justifyContent={'flex-end'}>
                  <Grid item>
                    <Button
                      sx={{ color: '#a0a0a0', width: '100px' }}
                      onClick={() => setOpenUploadCsvModal(false)}
                    >
                      Back
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      sx={{ width: '100px' }}
                      onClick={() => {
                        handleCsvUpload(file);
                        bulkUploadingStatus(true);
                      }}
                    >
                      Upload
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            ''
          )}
          {openAddBulkModal ? (
            <Grid container width={'100%'} direction={'column'} sx={{ p: '12px' }}>
              <Grid item width={'100%'}>
                <Grid container alignItems={'center'}>
                  <Grid item md={11}>
                    <Typography sx={{ fontSize: '20px', fontWeight: 600, ml: 2 }}>
                      Add Bulk Links
                    </Typography>
                  </Grid>
                  <Grid item md={1}>
                    <IconButton onClick={() => close(false)}>
                      <CloseIcon></CloseIcon>
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sx={{ mt: '10px' }}>
                <Grid container alignItems={'center'} spacing={2}>
                  <Grid item sx={{ ml: 2, p: '2px' }}>
                    <Button
                      onClick={() => {
                        setInstaSelected(true);
                        setInstaLinks([]);
                        setYtLinks([]);
                        setText('');
                        setYtSelected(false);
                        setPlatform('instagram');
                      }}
                      variant="contained"
                      sx={
                        instaSelected || planPlatform === 'instagram'
                          ? { width: '120px' }
                          : { width: '120px', background: '#dedede', color: '#000' }
                      }
                      startIcon={<InstagramIcon></InstagramIcon>}
                      disabled={planPlatform === 'youtube' ? true : false}
                    >
                      Instagram
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      onClick={() => {
                        setYtSelected(true);
                        setInstaSelected(false);
                        setInstaLinks([]);
                        setYtLinks([]);
                        setText('');
                        setPlatform('youtube');
                      }}
                      variant="contained"
                      sx={
                        ytSelected || planPlatform === 'youtube'
                          ? { width: '120px' }
                          : { width: '120px', background: '#dedede', color: '#000' }
                      }
                      startIcon={<YouTubeIcon></YouTubeIcon>}
                      disabled={planPlatform === 'instagram' ? true : false}
                    >
                      YouTube
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sx={{ p: '18px' }}>
                <Grid
                  container
                  sx={{ border: '1px dashed #454545', borderRadius: 1, height: 200, p: '18px' }}
                  direction="column"
                >
                  <Grid item>
                    <Typography>Paste your links here, one link per line</Typography>
                  </Grid>
                  <Grid item>
                    <TextField
                      multiline
                      rows={5}
                      placeholder={
                        planPlatform === 'instagram' || instaSelected
                          ? `https://www.instagram.com/username1\nhttps://www.instagram.com/username2\nhttps://www.instagram.com/username3`
                          : planPlatform === 'youtube' || ytSelected
                          ? `https://www.youtube.com/username1\nhttps://www.youtube.com/username2\nhttps://www.youtube.com/username3`
                          : `Please select a platform.`
                      }
                      sx={{
                        '& textarea': {
                          alignSelf: 'self-start',
                        },
                        overflowX: 'auto',
                      }}
                      style={{
                        width: '100%',
                        height: 140,
                      }}
                      value={instaLinks ? text : text}
                      onChange={handleOnChange}
                    ></TextField>
                  </Grid>
                </Grid>
                <Grid item>
                  <Typography sx={{ fontSize: 12, mt: 1 }}>
                    Note - You can add upto <span style={{fontWeight:700,color:'#333'}}>30 influencers</span> at a time for the selected platform.
                  </Typography>
                </Grid>
                <Grid item width={'100%'} sx={{ mt: '18px' }}>
                  <Grid container justifyContent={'flex-end'}>
                    <Grid item>
                      <Button
                        sx={{ color: '#a0a0a0', width: '100px' }}
                        onClick={() => setOpenAddBulkModal(false)}
                      >
                        Back
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        sx={{ width: '100px' }}
                        onClick={() => {
                          setInstaLinks([]);
                          setYtLinks([]);
                          setText('');
                          handleLinkUpload();
                          bulkUploadingStatus(true);
                        }}
                      >
                        Add
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            ''
          )}
        </Box>
      </Modal>
    </Grid>
  );
}

export default AddInfluencerModal;

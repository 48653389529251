import React from 'react';
import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Card, CardContent, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import moment from 'moment';

import { USE_ES } from 'config';

import CountDisplay from '../../../ux/CountDisplay';

import logo from '../../../../assets/instagram-logo.png';
import CircularProgress from '@material-ui/core/CircularProgress';
import ViewIcon from '../../../../assets/View.png';
import Thumbs_UpIcon from '../../../../assets/Thumbs_Up.png';
import CommentIcon from '../../../../assets/Comment.png';
import EngagementIcon from '../../../../assets/Engagement.png';
import EngagementRateIcon from '../../../../assets/EngagementRate.png';

import { getBrandedContentStats, getInstagramStatistic } from '../../../../api_handlers/reporting';
import { getInstagramStatisticES } from '../../../../api_handlers/es';

class Statistics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      total_likes: 0,
      total_comments: 0,
      total_views: 0,
      engagement_rate: 0,
      live_influencers_count:0,
      shortlisted_influencer_count:0,
      total_videos: 0,
      story_engagement: 0,
      loading: true,
      brandedContentStats: null,
    };
  }

  async getData() {
    const filter = {
      influencer_id: this.props.influencerId,
      start_date: this.props.startDate ? moment(this.props.startDate).format('YYYY-MM-DD') : null,
      end_date: this.props.endDate ? moment(this.props.endDate).format('YYYY-MM-DD') : null,
      "post_type": this.props.selectedFeedTab==0?this.props.postType:this.props.selectedFeedTab==1?'story':null,
    };

    const fetchFunction = USE_ES ? getInstagramStatisticES : getInstagramStatistic;

    const resp = await fetchFunction(filter);
    this.setState({ ...resp.data, loading: false });
    console.log('resp',resp.data)

    const resp2 = await getBrandedContentStats(filter);
    this.setState({
      brandedContentStats: { ...resp2 },
    });
  }

  componentDidMount() {
    if (this.props.onRef) this.props.onRef(this);
    setTimeout(() => {
      this.getData();
    }, 30);
  }

  reloadData() {
    this.getData();
  }

  render() {
    return (
      <Card>
        <CardContent>
          <Grid container>
            <Grid
              item
              md={12}
              style={{
                marginBottom: '30px',
              }}
            >
              <Grid container justify="space-between">
                <Grid item md={4}>
                  <Grid container alignItems="center">
                    <Grid item md={2}>
                      <img src={logo} width={'29'} height={'29'} />
                    </Grid>
                    <Grid
                      item
                      md={4}
                      style={{
                        fontSize: '13px',
                        fontWeight: 500,
                      }}
                    >
                      Statistics
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  md={3}
                  style={{
                    fontSize: '12px',
                    lineHeight: '14px',
                    textAlign: 'right',
                    color: '#5C5C5C',
                  }}
                >
                  {this.props.startDate && this.props.endDate
                    ? `${moment(this.props.startDate).format('DD/MM/YY')} - ${moment(
                        this.props.endDate,
                      ).format('DD/MM/YY')}`
                    : 'Overall data'}
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={12}>
              {this.state.loading ? (
                <CircularProgress />
              ) : (
                <Grid container spacing={0} justify="space-around">
                <Grid container spacing={0} style={{justifyContent: 'space-between', paddingBottom: '2%'}}>
                  <Grid item md={2} xs={12}>
                    <CountDisplay
                      icon={ViewIcon}
                      label={'Total Video Views'}
                      count={this.state.total_video_views?this.state.total_video_views:0}
                      color={'#7158F1'}
                    />
                    {
                      console.log("this.state.total_video_views",this.state.total_video_views)
                    }
                  </Grid>
                  <Grid item md={2} xs={12}>
                    <CountDisplay
                      icon={Thumbs_UpIcon}
                      label={'Engaged Users'}
                      count={
                        (this.state.total_likes || 0) +
                          (this.state.total_comments || 0) +
                          (this.state.total_saved || 0)+ 
                          (this.state.story_engagement || 0)|| 0
                      }
                      color={'#B563DC'}
                      tooltipInfo={
                        <React.Fragment>
                          <div>
                            <b>Likes</b>: {this.state.total_likes || 0}
                          </div>
                          <div>
                            <b>Comments</b>: {this.state.total_comments || 0}
                          </div>
                          <div>
                            <b>Saves</b>: {this.state.total_saved || 0}
                          </div>
                          <div>
                            <b>Story Engagement</b>: {this.state.story_engagement || 0}
                          </div>
                        </React.Fragment>
                      }
                    />
                  </Grid>
                  <Grid item md={2} xs={12}>
                    <CountDisplay
                      icon={Thumbs_UpIcon}
                      label={'Likes'}
                      count={this.state.total_likes || 0}
                      color={'#0CBD8C'}
                    />
                  </Grid>
                  <Grid item md={2} xs={12}>
                    <CountDisplay
                      icon={CommentIcon}
                      label={'Comments'}
                      count={this.state.total_comments || 0}
                      color={'#0CBD8C'}
                    />
                  </Grid>
                  <Grid item md={2} xs={12}>
                    <CountDisplay
                      icon={CommentIcon}
                      label={'Total Conversions'}
                      count={this.state.brandedContentStats?.conversions | 0}
                      color={'#0CBD8C'}
                    />
                  </Grid>
                  </Grid>
                  <Grid container spacing={0} style={{justifyContent: 'flex-start'}}> 
                  <Grid item md={2} xs={0} style={{marginRight: '4.4%', minWidth: '17%'}}>
                    <CountDisplay
                      icon={ViewIcon}
                      label={'Total Reach'}
                      count={
                        (this.state.brandedContentStats?.reach?this.state.brandedContentStats?.reach:0) + (this.state.total_reach?this.state.total_reach:0)
                      }
                      color={'#7158F1'}
                      tooltipInfo={
                        <React.Fragment>
                          <div>
                            <b>Paid</b>: {this.state.brandedContentStats?.reach | 0}
                          </div>
                          <div>
                            <b>Organic</b>: {this.state.total_reach | 0}
                          </div>
                        </React.Fragment>
                      }
                    />
                  </Grid>
                  <Grid item md={2} xs={0} style={{marginRight: '4.4%', minWidth: '17%'}}>
                    <CountDisplay
                      icon={ViewIcon}
                      label={'Total Impressions'}
                      count={
                        (this.state.brandedContentStats?.impressions | 0) +
                        (this.state.total_impressions | 0)
                      }
                      color={'#7158F1'}
                      tooltipInfo={
                        <React.Fragment>
                          <div>
                            <b>Paid</b>: {this.state.brandedContentStats?.impressions | 0}
                          </div>
                          <div>
                            <b>Organic</b>: {this.state.total_impressions | 0}
                          </div>
                        </React.Fragment>
                      }
                    />
                  </Grid>
                  <Grid item md={2} xs={0} style={{marginRight: '3.4%'}}>
                    <CountDisplay
                      icon={CommentIcon}
                      label={'Live Posts'}
                      count={this.state.total_videos || 0}
                      color={'#0CBD8C'}
                    />
                  </Grid>
                  <Grid item md={2} xs={0}>
                    <CountDisplay
                      icon={EngagementRateIcon}
                      label={`Influencer's live count`}
                      multiCount={`${this.state.live_influencers_count}/${this.state.shortlisted_influencer_count}`}
                      color={'#0CBD8C'}
                    />

                  </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }
}

// export default withRouter(Statistics);
const mapStateToProps = (state) => {
  return {
    campaignId: state.campaign.campaignId,
  };
};

export default connect(mapStateToProps)(withRouter(Statistics));

import { FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import InstagramIcon from '@mui/icons-material/Instagram';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import reels from '../../../../../../assets/instagram-reels-icon.webp';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import GridViewIcon from '@mui/icons-material/GridView';
import TheatersIcon from '@mui/icons-material/Theaters';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import { fetchPostWiseBifurcation } from 'api_handlers/planner2';

import { Grid } from '@mui/material';

function StatsFilters({ id, influencer, platform,filteredData}) {
  const [filterViaTypes, SetFilterViaTypes] = useState('all');
  const [filterViaPayment, setFilterViaPayment] = useState('all');
  const [postsNumber, setPostsNumber] = useState(12);
  // const [platform,setplatform] = useState({})
  console.log('postsNumber', filterViaTypes, filterViaPayment, postsNumber, id, influencer,platform);
  useEffect(()=>{
    if (id) {
      if (filterViaPayment === 'all') {
        fetchPostWiseBifurcation(
          id,
          false,
          filterViaTypes,
          postsNumber,
          filterViaTypes,
          postsNumber,
        ).then(res=>filteredData(res)); 
      } else if (filterViaPayment === 'paid') {
        fetchPostWiseBifurcation(
          id,
          true,
          filterViaTypes,
          postsNumber,
          filterViaTypes,
          postsNumber,
        ).then(res=>filteredData(res));
      }
    }
  },[filterViaTypes,filterViaPayment,postsNumber])
  return (
    <Grid container>
      <Grid item md={12} style={{ marginRight: '10px' }}>
        <Grid container justifyContent="flex-end" spacing={1}>
          {platform === 'instagram' ? (
            <Grid item style={{ width: '200px', marginTop: '6px' }}>
              <Grid
                container
                style={{ borderRadius: '5px', background: '#f5f5f5', padding: '2px' }}
              >
                <Grid
                  item
                  md={2}
                  style={{
                    cursor: 'pointer',
                    borderRadius: '5px',
                    background: filterViaTypes === 'all' ? '#fff' : '',
                  }}
                  onClick={() => SetFilterViaTypes('all')}
                >
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="center"
                    style={{ height: '100%' }}
                  >
                    <Grid item>
                      <Typography
                        style={{
                          fontSize: '11px',
                          color: filterViaTypes === 'all' ? '#5e85d0' : '#000',
                          fontWeight: 600,
                        }}
                      >
                        All
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  md={5}
                  style={{
                    cursor: 'pointer',
                    borderRadius: '5px',
                    background: filterViaTypes === 'post' ? '#fff' : '',
                  }}
                  onClick={() => SetFilterViaTypes('post')}
                >
                  <Grid container alignItems="center" justifyContent="center" spacing={1}>
                    <Grid item>
                      <InstagramIcon
                        style={{
                          width: '14px',
                          marginTop: '4px',
                          color: filterViaTypes === 'post' ? '#5e85d0' : '#000',
                        }}
                      ></InstagramIcon>
                    </Grid>
                    <Grid item>
                      <Typography
                        style={{
                          fontSize: '11px',
                          color: filterViaTypes === 'post' ? '#5e85d0' : '#000',
                          fontWeight: 600,
                        }}
                      >
                        Posts
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  md={5}
                  style={{
                    cursor: 'pointer',
                    borderRadius: '5px',
                    background: filterViaTypes === 'reel' ? '#fff' : '',
                    height: '100%',
                  }}
                  onClick={() => SetFilterViaTypes('reel')}
                >
                  <Grid container alignItems="center" justifyContent="center" spacing={1}>
                    <Grid item>
                      <VideoLibraryIcon
                        style={{
                          width: '14px',
                          marginTop: '4px',
                          color: filterViaTypes === 'reel' ? '#5e85d0' : '#000',
                        }}
                      ></VideoLibraryIcon>
                    </Grid>
                    <Grid item>
                      <Typography
                        style={{
                          fontSize: '11px',
                          color: filterViaTypes === 'reel' ? '#5e85d0' : '#000',
                          fontWeight: 600,
                        }}
                      >
                        Reels
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            ''
          )}

          <Grid item style={{ width: '200px', marginTop: '6px' }}>
            <Grid container style={{ borderRadius: '5px', background: '#f5f5f5', padding: '2px' }}>
              <Grid
                item
                md={6}
                style={{
                  cursor: 'pointer',
                  borderRadius: '5px',
                  background: filterViaPayment === 'all' ? '#fff' : '',
                }}
                onClick={() => setFilterViaPayment('all')}
              >
                <Grid
                  container
                  alignItems="center"
                  justifyContent="center"
                  style={{ height: '100%' }}
                >
                  <Grid item>
                    <GridViewIcon
                      style={{
                        width: '14px',
                        marginTop: '4px',
                        color: filterViaPayment === 'all' ? '#5e85d0' : '#000',
                      }}
                    ></GridViewIcon>
                  </Grid>
                  <Grid item sx={{ ml: '4px' }}>
                    <Typography
                      style={{
                        fontSize: '11px',
                        color: filterViaPayment === 'all' ? '#5e85d0' : '#000',
                        fontWeight: 600,
                      }}
                    >
                      All Posts
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                md={6}
                style={{
                  cursor: 'pointer',
                  borderRadius: '5px',
                  background: filterViaPayment === 'paid' ? '#fff' : '',
                }}
                onClick={() => setFilterViaPayment('paid')}
              >
                <Grid container alignItems="center" justifyContent="center" spacing={1}>
                  <Grid item md={2} width={'100%'} style={{ textAlign: 'end' }}>
                    <CurrencyRupeeIcon
                      style={{
                        width: '14px',
                        marginTop: '4px',
                        color: filterViaPayment === 'paid' ? '#5e85d0' : '#000',
                      }}
                    ></CurrencyRupeeIcon>
                  </Grid>
                  <Grid
                    item
                    md={8}
                    width={'100%'}
                    style={{ textAlign: 'start' }}
                    sx={{ ml: '2px' }}
                  >
                    <Typography
                      style={{
                        fontSize: '11px',
                        color: filterViaPayment === 'paid' ? '#5e85d0' : '#000',
                        fontWeight: 600,
                      }}
                    >
                      Paid Posts
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                md={5}
                style={{
                  cursor: 'pointer',
                  borderRadius: '5px',
                  background: filterViaPayment === 'organic' ? '#fff' : '',
                }}
                onClick={() => setFilterViaPayment('organic')}
              >
                {/* <Grid
                  container
                  alignItems="center"
                  justifyContent="center"
                  style={{ height: '100%' }}
                >

                  <Grid item md={4}>
                    <GridViewIcon
                      style={{
                        width: '14px',
                        marginTop: '4px',
                        color: filterViaPayment === 'organic' ? '#5e85d0' : '#000',
                      }}
                    ></GridViewIcon>
                  </Grid>
                  <Grid item md={8} width={'100%'} textAlign={'start'}>
                    <Typography
                      style={{
                        fontSize: '11px',
                        color: filterViaPayment === 'organic' ? '#5e85d0' : '#000',
                        fontWeight: 600,
                      }}
                    >
                      Organic
                    </Typography>
                  </Grid>
                </Grid> */}
              </Grid>
            </Grid>
          </Grid>

          <Grid item style={{ width: '150px', marginTop: '4px', height: '53px' }}>
            <Grid
              container
              style={{ borderRadius: '5px', background: '#fff', padding: '2px', height: '100%' }}
            >
              <Grid
                item
                md={12}
                style={{
                  cursor: 'pointer',
                  borderRadius: '5px',
                  background: '#f5f5f5',
                }}
              >
                <Grid
                  container
                  alignItems="center"
                  justifyContent="center"
                  style={{ height: '100%' }}
                >
                  <Grid item style={{ width: '100%' }}>
                    <FormControl
                      fullWidth
                      variant="outlined"
                      sx={{
                        '& .MuiOutlinedInput-root:hover': {
                          '& fieldset': {
                            borderColor: '#f6f6f6',
                          },
                        },
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: '#f6f6f6',
                          },
                        },
                        '& .MuiOutlinedInput-root:after': {
                          '& fieldset': {
                            borderColor: '#f6f6f6',
                          },
                        },
                      }}
                    >
                      {/* <InputLabel id="demo">Age</InputLabel> */}
                      <Select
                        labelId="demo"
                        id="demo"
                        value={postsNumber}
                        label="demo"
                        onChange={(event) => {
                          setPostsNumber(event.target.value);
                        }}
                        MenuProps={{
                          PaperProps: {
                            style: {
                              backgroundColor: '#f8f8f8',
                            },
                          },
                        }}
                        style={{
                          fontSize: '11px',
                          fontWeight: 600,

                          background: '#f5f5f5',
                          borderRadius: '5px',
                          maxHeight: '30px',
                        }}
                      >
                        <MenuItem value={12} style={{ maxHeight: '20px', fontSize: '14px' }}>
                          {' '}
                          12 Posts
                        </MenuItem>
                        <MenuItem value={15} style={{ maxHeight: '20px', fontSize: '14px' }}>
                          15 Posts
                        </MenuItem>
                        <MenuItem value={20} style={{ maxHeight: '20px', fontSize: '14px' }}>
                          20 Posts
                        </MenuItem>
                        <MenuItem value={25} style={{ maxHeight: '20px', fontSize: '14px' }}>
                          25 Posts
                        </MenuItem>
                        <MenuItem value={30} style={{ maxHeight: '20px', fontSize: '14px' }}>
                          30 Posts
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default StatsFilters;

import React, {Component} from 'react';
import { connect } from 'react-redux';


import {Grid} from '@mui/material';

import { gridSpacing } from 'store/constant';

import InfluencerList from '../InfluencerList';
import Search from '../Search';

import { addFilterInPayload } from 'actions/planner2';

class InfluencerDiscover extends Component {
  constructor(props) {
    super(props);
    this.state={
      filters:[]
    }
  }

  updateFilters = ({sortBy}) => {
    this.props.addFilterInPayload('sortBy', sortBy, true);
  }

  render() {
    const { influencers, loading, totalResults } = this.props;

    return <Grid container spacing={gridSpacing}>
      <Grid item xs={12} md={12}> 
        <Search
          fetchInfluencers={this.fetchInfluencers} 
          plannerFilter={async val=>await this.setState({filters:val})}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        
        <InfluencerList
          isDiscover={true}
          influencers={influencers}
          loading={loading}
          totalResults={totalResults}
          updateFilters={this.updateFilters}
          useCategorySort={true}
          filters={this.state.filters}
        />
      </Grid>
    </Grid>
  }
}

const mapStateToProps = (state) => ({
  payload: state.planner2.payload,
  loading: state.planner2.loading,
  influencers: state.planner2.influencers,
  totalResults: state.planner2.totalInfluencersCount,
});

const actionPlanner = {
  addFilterInPayload: addFilterInPayload,
};

// export default Planner;
export default connect(mapStateToProps, actionPlanner)(InfluencerDiscover);